.custom-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Arial", sans-serif;
}

.custom-input-label {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 6px;
}

.custom-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #eeebeb;
  border-radius: 8px;
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
}

.custom-input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  min-width: 0;
}

.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-input[type='number'] {
  -moz-appearance: textfield;
}

.custom-input-suffix {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.custom-input-error {
  border: 1px solid red;
}

.custom-input-error-text {
  margin-top: 4px;
  font-size: 14px;
  color: red;
}
