.action-progress {
  border-radius: 51px;
  max-width: 240px;
  position: sticky;
  margin: 0 auto;
  bottom: 15px;
  background-color: #f1f5f9;
  padding: 10px 15px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 3px 1px #0000001c;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.action-progress-container {
  max-width: 270px;
}

.action-progress-full {
  max-width: max-content;
}

.action-progress-data {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  text-align: left;
  color: #4fa6d8;
  margin-left: 10px;
}

.action-progress-icon {
  font-size: 24px;
}

.action-progress-resource-name {
  font-size: 24px;
  font-weight: 500;
}

.action-progress-resource-uuid {
  font-size: 12px;
  opacity: 1;
  visibility: visible;
  max-height: 100px; /* Set to a value large enough to fit the content */
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
}

.action-progress-steps {
  display: flex;
  align-items: center;
}

.action-progress-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.action-progress-step-data {
  text-align: right;
  margin-right: 10px;
}

.action-progress-current-step {
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
}

.action-progress-next-step {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 300;
  opacity: 1;
  visibility: visible;
  max-height: 20px; /* Adjust to match the actual content height */
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
}

.action-progress-next-step-hidden {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}

.success-main-action-progress-bar {
  animation: move-up-with-delay-action-progress 3s ease-in-out 3s forwards;
}

.action-progress-hide-button-container {
  position: absolute;
  bottom: 94px;
  right: 18%;
  background-color: #496eb2;
  border-radius: 10px 0 0 0;
  width: 70px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-progress-hide-button {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.action-progress-hide-button-test {
  transform: rotate(49deg);
  margin-left: 4px;
  font-size: 20px;
}

.action-progress-hidden {
  transform: translate(0, 15%);
  bottom: 25px;
  border-radius: 30px;
}

/* .action-progress-content-hidden { */

/*  display: none; */

/*  transition: display 0.3s ease-in-out, opacity 0.3s ease-in-out; */

/* } */

.action-progress-bar-smaller {
  height: 40px;
  width: 40px;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.action-progress-content-triggered {
  margin-left: 0;
}

.action-progress-content-hidden {
  opacity: 0;
  visibility: hidden;
  max-height: 0; /* To simulate collapsing */
  overflow: hidden; /* Prevent content overflow */
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
}

.action-progress-content-visible {
  opacity: 1;
  visibility: visible;
  max-height: 500px; /* Set a value large enough to fit the content */
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
}

.action-progress-resource-uuid-hidden {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}

.action-progress-mobile-hidden {
  max-width: 180px;
}

.action-progress-mobile-hidden-container {
  max-width: 210px;
}

.action-progress-next-icon {
  font-size: 20px;
  color: #50a6d8;
}
