.progress-circle-container {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.progress-circle {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
}

.progress-circle-background {
  fill: none;
  stroke: #e0e0e0; /* Light grey background */
  stroke-width: 3;
}

.progress-circle-progress {
  fill: none;
  stroke: #4caf50; /* Green progress */
  stroke-width: 3;
  stroke-linecap: round;
  stroke-dasharray: 0 100;
  transition: stroke-dasharray 0.3s ease;
}

.progress-circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
