.lps-card {
  background: #3c7dc3;
  border-radius: 22px;
  padding: 27px;
  color: #fff;
}

.lps-card-header {
  text-align: left;
}

.lps-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.lps-modules {
  font-weight: 600;
  text-align: left;
  margin: 25px 0 15px 0;
}

.module-card {
  border-radius: 22px;
  box-shadow: 0 3px 4px #0000002e;
  color: #fff;
  border: 1px solid #eaeaea;
  padding: 20px 24px;
  margin-bottom: 25px;
  width: 100%;
}

.module-card-bg-dose {
  background: linear-gradient(154deg, #347ccb 3.59%, #1a3e65 79.33%);
}

.module-card-bg-build {
  background: linear-gradient(154deg, #cc7007 3.59%, #452500 79.33%);
}

.module-card-bg-overflow {
  background: linear-gradient(154deg, #0aab88 3.59%, #044537 79.33%);
}

.module-card-empty-bg {
  background: linear-gradient(277deg, #9d9d9d 49.91%, rgba(95, 95, 95, 0.79) 64.94%);
  border: 1px solid #c9c5c5;
}

.module-title-size {
  font-size: 20px;
}

.module-id {
  font-size: 12px;
  font-weight: 200;
}

.lps-module-card-action-btn {
  cursor: pointer;
  transition: 0.3s;
  border-radius: 30px;
  border: 5px solid transparent;
}

.lps-module-card-action-btn:hover {
  border: 5px solid #ffffff30;
}

.lps-module-card-dose {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.lps-module-card-dose-right-content {
  text-align: right;
  margin-bottom: 10px;
  align-self: flex-end;
}

.lps-module-card-resource {
  font-size: 22px;
  font-weight: 600;
}

.lps-module-card-dose-quantity {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.lps-module-card-dose-materials {
  font-size: 16px;
  font-weight: 200;
}

.mhs-card {
  border: 1px solid #eaeaea;
  background: linear-gradient(154deg, #4b4b4b 3.59%, #4e5e71 79.33%);
  border-radius: 22px;
  padding: 27px;
  color: #fff;
  margin-bottom: 45px;
}

.mhs-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.mhs-card-batch {
  font-size: 27px;
  font-weight: 600;
}

.mhs-card-bottom-content {
  display: flex;
  justify-content: space-between;
}

.mhs-card-machine-type {
  align-self: end;
  text-align: left;
}

.mhs-card-top-content {
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.mhs-card-batch-info-top {
  display: flex;
  align-self: baseline;
  width: 100%;
  justify-content: flex-end;
}

.mhs-card-batch-details {
  margin-bottom: 15px;
  text-align: left;
}

.mhs-module-card-dose-quantity {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.mhs-module-card-dose-materials {
  font-size: 16px;
  font-weight: 200;
}

.mhs-actions {
  border-radius: 30px;
  background: #e7e9eb;
  padding: 30px 35px;
  margin-bottom: 25px;
}

.mhs-menu-button {
  background: linear-gradient(180deg, #545454 0%, #1a4865 100%);
  border-radius: 8px;
}

/* hides caret in dropdown */
.mhs-menu-button::after {
  display: none !important;
}

.mhs-menu-inner {
  padding: 0;
  border-radius: 8px;
  max-width: 180px;
}

.mhs-menu-link {
  padding: 0.5rem 0.8rem;
  font-size: 14px;
  border-radius: 8px;
  white-space: normal;
  text-align: center;
}

.mhs-menu-link:active {
  color: initial;
  background-color: transparent;
}

.action-btn-cyclone {
  padding: 10px 20px;
  min-width: 110px;
}

.action-btn-cyclone > div {
  font-size: 14px;
  font-weight: 200;
}

.action-btn-cyclone > svg {
  font-size: 18px;
}

.action-btn-cyclone.disabled,
.action-btn-cyclone:disabled {
  cursor: not-allowed;
  border-color: #1b3758;
  background-color: #1b3758 !important;
}

.action-mhs-cyclone-load {
  border-radius: 20px;
  transition: 0.3s;
  opacity: 1;
  border-color: #456885;
  background: linear-gradient(180deg, #545454 0%, #1a4865 100%);
}

.action-mhs-cyclone-load:hover {
  opacity: 0.9;
}

.action-mhs-cyclone-load.disabled:hover,
.action-mhs-cyclone-load:disabled:hover {
  opacity: 0.4;
}

.action-mhs-cyclone-sieve-to-output {
  border-radius: 20px;
  transition: 0.3s;
  opacity: 1;
  border-color: #3f5442;
  background: linear-gradient(180deg, #545454 0%, #1f542a 100%);
}

.action-mhs-cyclone-sieve-to-output:hover {
  opacity: 0.9;
}

.action-mhs-cyclone-sieve-to-output.disabled:hover,
.action-mhs-cyclone-sieve-to-output:disabled:hover {
  opacity: 0.4;
}

.action-mhs-cyclone-load-from-input {
  border-radius: 20px;
  transition: 0.3s;
  opacity: 1;
  border-color: #34476b;
  background: linear-gradient(180deg, #545454 0%, #274076 100%);
}

.action-mhs-cyclone-load-from-input:hover {
  opacity: 0.9;
}

.action-mhs-cyclone-load-from-input.disabled:hover,
.action-mhs-cyclone-load-from-input:disabled:hover {
  opacity: 0.4;
}

.mhs-cyclone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9e9e9;
  margin: 20px 0;
}

.mhs-cyclone-outer {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: linear-gradient(180deg, #545454 0%, #1a4865 100%);
  border: 6px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.mhs-cyclone-inner {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mhs-cyclone-title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.mhs-cyclone-icon-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mhs-cyclone-arrow {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1;
  margin-bottom: 4px;
}

.mhs-cyclone-quantity {
  color: #fff;
  font-size: 0.9rem;
}

.mhs-modules-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.module-title {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 600;
}

.mhs-module-card {
  text-align: left;
}

@media (min-width: 300px) and (max-width: 600px) {
  .mhs-card-batch-info-top {
    flex-direction: column;
    align-items: flex-end;
  }

  .mhs-card-batch-info-top > div {
    margin-right: 0;
  }

  .mhs-card-batch-details {
    order: -1;
  }

  .mhs-module-card-dose-quantity {
    font-size: 26px;
  }

  .mhs-card-batch {
    font-size: 26px;
  }

  .mhs-modules-container {
    flex-direction: column;
  }
}

.lps-build {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lps-build-ready {
  font-size: 20px;
}

.lps-overflow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lps-overflow-ready {
  font-size: 20px;
}
