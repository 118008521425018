body {
  --primary: rgb(54, 142, 196);

  /* background-color: rgba(54,142,196,1); */
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #e09d40;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ff9300, 0 0 5px #ff9300;
  opacity: 1;
  transform: rotate(3deg) translate(0, -4px);
}

main {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}

.spinner-border-sm {
  border-width: 0.1em;
}

.form-login {
  width: 100%;
  max-width: 330px;
}

.navbar-dark,
.navbar-dark .navbar-text {
  color: #fff;
}

.gap30 {
  gap: 30px;
}

/* .bg-primary {
  background-color: #005685 !important;
} */

.qr-reader {
  position: relative;
  width: 100%;
}

.qr-reader::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.qr-reader-content {
  position: absolute;
  top: 0;
}

.navbar-square-item {
  width: 2rem;
  height: 2rem;
}

.avatar {
  text-transform: uppercase;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);
}

.cursor-pointer {
  cursor: pointer;
}

.thumbnail-box {
  height: 0;
  overflow: hidden;
  padding-top: 75%; /* 4:3 aspect ratio */
  background: #d1d1d1;
  position: relative;
}

.thumbnail-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.print-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  text-align: left;
  font-size: 0.8em;
}

.print-info-grid dt {
  text-align: right;
  font-weight: normal;
}

.btn-inline,
.alert.small .btn-sm {
  font-size: inherit;
  line-height: inherit;
  vertical-align: baseline;
  padding: 0;
}

.scan-button svg {
  width: 1em;
  height: 1em;
}

.scan-button [data-icon="arrow-alt-up"],
.scan-button [data-icon="arrow-alt-down"] {
  width: 0.5em;
  height: 0.5em;
}

.scan-button [data-icon="arrow-alt-up"] {
  margin-bottom: 0.4em;
}

.scan-button [data-icon="arrow-alt-down"] {
  margin-top: 0.5em;
}

.print-check-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 300px) and (max-width: 600px) {
  .run-workstation-body {
    text-align: center !important;
  }

  .labelsStyleTitle {
    display: block;
    margin-bottom: 0.5em;
  }

  .run-workstation-title {
    display: block !important;
  }

  .run-workstation-title > span {
    margin-top: 5px;
    white-space: break-spaces;
  }

  .line-item-instructions {
    word-break: break-word;
  }

  .work-instructions-body {
    flex-direction: column;
    align-items: center;
  }

  .work-instructions-body-model {
    flex: 0 0 66.666667% !important;
    max-width: 66.666667% !important;
  }

  .work-instructions-body-content {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }

  .work-instructions-body-content-text {
    margin-right: 0 !important;
    margin-top: 1rem;
  }

  .work-instructions-carousel {
    max-width: 235px !important;
  }

  .start-run-btn {
    position: fixed;
    bottom: 5%;
    transform: translate(-50%, 0);
  }

  .work-instructions-carousel-next-arrow {
    right: -5px !important;
  }

  .work-instructions-carousel-prev-arrow {
    left: -5px !important;
  }

  .notification {
    left: 50% !important;
    width: 350px !important;
  }
}

@media (min-width: 600px) {
  .print-check-in {
    flex-direction: row;
    min-width: 600px;
    max-width: none;
  }

  .print-info {
    max-width: 400px;
    padding: 0 10px;
  }

  .print-info hr {
    grid-column: 1/3;
    width: 100%;
    color: #000;
    margin: 0;
    border-top: 2px solid #000;
  }
}

.install-prompt {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: none;
  transform: translateY(110%);
  opacity: 0.1;
  transition: all 200ms ease-in-out;
}

@media print {
  .install-prompt {
    display: none;
  }
}

.install-prompt .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.slide-enter {
  display: block;
}

.slide-enter-done {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.modal-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/*
  This needs to be reapplied because bootstrap expects that you just remove the
  "show" class, but react-modal doesn't let us do that.
*/

.modal.before-close {
  opacity: 0;
}

.modal.fade.before-close .modal-dialog {
  transform: translate(0, -50px);
}

.notes-button {
  position: fixed;
  right: 0.75rem;
  bottom: 0.75rem;
  width: 3rem;
  height: 3rem;
  font-size: 1.5em;
}

.part-img {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.work-instruction {
  padding: 0.25em 1em;
}

.slick-next::before,
.slick-prev::before {
  display: none;
}

.custom-file-label.plain {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal !important;
}

.custom-file-label.plain::after {
  display: none;
}

.work-instruction-preview-image {
  width: 100%;
  height: 75px;
}

.work-instruction-preview-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.work-instruction-progress {
  background-color: #fff;
  border: 1px solid #bee5eb; /* info alert border */
}

/* Utils for Materials Management */
.mm-btn-text {
  line-height: 1em;
}

.mm-pw-100 {
  width: 100px;
}

.mm-ph-100 {
  height: 100px;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-items-center {
  justify-items: center;
}

.position-relative {
  position: relative !important;
}

.rangeInputAbsolute {
  position: absolute;
  top: -15px;
  right: 50%;
  left: 50%;
}

.formatted-converted-units {
  background-color: #e0e0e0;
  padding: 5px;
  border-radius: 5px;
  color: black;
}

.align-items-center {
  align-items: center;
}

.ml-5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mr3 {
  margin-right: 3px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr30 {
  margin-right: 30px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb8 {
  margin-bottom: 8px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mt15 {
  margin-top: 15px;
}

.ml5 {
  margin-left: 5px;
}

.ml15 {
  margin-left: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt10 {
  margin-top: 10px;
}

.font-weight-500 {
  font-weight: 500;
}

.mt30 {
  margin-top: 30px;
}

.mt60 {
  margin-top: 60px;
}

/* HACK: Fix later */
.header-margin {
  margin-bottom: 1rem !important;
}

.header-margin-large {
  margin-bottom: 4rem !important;
}

/* Bootstrap overrides */

.navbar {
  flex-wrap: nowrap;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.4;
}

.action-btn {
  border-color: #1b3758;
  background-color: #1b3758;
}

.action-btn.disabled,
.action-btn:disabled {
  cursor: not-allowed;
  border-color: #1b3758;
  background-color: #1b3758 !important;
}

.action-btn:hover {
  color: #fff;
  background-color: #31619b;
  border-color: #31619b;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.load-container-title {
  font-style: italic;
  max-width: 200px;
}

/* Separator (with text content at the center) */

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0.75rem;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #d4d4d4;
}

.separator::before {
  margin-right: 0.5em;
}

.separator::after {
  margin-left: 0.5em;
}

.hide-caret.dropdown-toggle::after {
  content: none;
}

.slick-dots.workinstructions-dots {
  position: relative;
}

.slick-dots.workinstructions-dots ul {
  padding: 0;
  bottom: 0;
}

.slick-dots.workinstructions-dots li {
  position: relative;
}

.slick-dots.workinstructions-dots li button::before {
  font-size: 50px;
  content: '\203e';
}

.required-text {
  color: #dc3545;
  margin-left: 6px;
}

.instructions-external-link-icon {
  color: #007bff;
  margin-left: 12px;
}

.link-icon {
  color: #007bff;
  margin-left: 6px;
  cursor: pointer;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.completeModalError {
  padding-top: 10px;
}

.labelStyleContainer {
  display: inline-block;
  margin-top: 10px;
}

.labelStyle {
  border: 1px solid #00000017;
  padding: 2px 12px;
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: #6c757d;
  border-radius: 30px;
  box-shadow: -1px 1px 1px #0000001c;
  margin-left: 5px;
  margin-bottom: 0;
}

.labelStyleColor {
  border: 1px solid #8d8d8d;
  padding: 0 7px;
  border-radius: 100%;
  margin-left: 5px;
}

.run-header-status hr {
  grid-column: 1/3;
  width: 100%;
  color: #000;
  margin: 0;
  border-top: 2px solid #000;
}

.run-workstation-body {
  background: #9ec5f8;
  padding: 5px 10px;
  text-align: left;
  border-radius: 5px;
  margin-bottom: 10px;
}

.run-workstation-title {
  display: flex;
}

.run-workstation-user-icon-active {
  font-size: 22px;
  color: rgb(255, 165, 0);
}

.run-workstation-user-icon-away {
  font-size: 22px;
  opacity: 0.7;
  color: #6c757d;
}

.run-workstation-title-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-dark-lavender {
  background-color: #8d6ffd;
  color: white;
}

.logout-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: 1px solid #000;
  background-color: #fff;
  z-index: 999;
}

.reload-btn {
  position: fixed;
  right: 20px;
  bottom: 70px;
  border: 1px solid #000;
  background-color: #fff;
  z-index: 999;
}

.scan-btn {
  position: fixed;
  left: 20px;
  bottom: 20px;
  border: 1px solid #000;
  background-color: #fff;
  z-index: 999;
}

.work-instructions-carousel {
  display: flex;
  justify-content: center;
  position: relative !important;
  width: 100% !important;
  border: 1px solid #e4e6ea;
  border-radius: 5px;
  padding: 5px;
  max-width: 245px;
  margin-right: 5px;
  left: inherit !important;
  transform: inherit !important;
  overflow: hidden;
}

.work-instructions-carousel-item {
  width: 23px !important;
  height: 23px !important;
  display: flex !important;
  justify-content: center;
  text-align: center;
  line-height: 20px !important;
  font-size: 12px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.34);
  margin: 3px;
  cursor: pointer;
  padding: 2px 8px;
  color: #fff !important;
  font-weight: bold;
  opacity: 0.7 !important;
  user-select: none !important;
  transition: 0.4s transform, 0.4s left, 0.1s background;
}

.work-instructions-carousel-item-active {
  opacity: 1 !important;
}

.work-instructions-carousel-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.work-instructions-carousel-item-submitted {
  background: #62d3ad !important;
}

.work-instructions-carousel-submitted {
  font-size: 12px;
}

.work-instructions-carousel-arrow {
  width: 100%;
  height: 100%;
  color: #000;
}

.work-instructions-carousel-prev-arrow {
  cursor: pointer;
  width: 23px !important;
  height: 23px !important;
  position: absolute;
  left: 0;
  user-select: none !important;
  top: 8px;
}

.work-instructions-carousel-next-arrow {
  cursor: pointer;
  width: 23px !important;
  height: 23px !important;
  position: absolute;
  right: 0;
  user-select: none !important;
  top: 8px;
}

.work-instructions-carousel-inactive-arrow {
  opacity: 0.3 !important;
}

.work-instructions-carousel-container {
  transition: transform 400ms ease 0ms;
  width: 200px;
  overflow: hidden;
}

.work-instructions-carousel-submit-btn {
  max-height: 38px;
  max-width: 40px;
}

.work-instructions-carousel-submit-btn-inactive {
  background-color: #9ea29f;
  border-color: #9ea29f;
}

.work-instructions-carousel-submit-btn-inactive:hover {
  background-color: #9ea29f;
  border-color: #9ea29f;
}

.work-instructions-carousel-item-invalid {
  background: #ffc107 !important;
}

.work-instructions-carousel-slick {
  margin-bottom: 20px;
}

.notification {
  border-radius: 5px;
  position: relative;
  margin-top: 20px;
  -ms-flex-wrap: wrap;
  width: 568px;
  left: 15%;
  transform: translate(-50%, 0%);
}

.notification-header {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border: transparent;
  align-items: center;
  background: #ffc107;
  border-radius: 5px 5px 0 0;
}

.notification-header span {
  margin-left: 10px;
}

.notification-header-dismissed {
  background: #a5c4f7;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}

.notification-header-dismissed > svg {
  cursor: pointer;
  z-index: 2;
}

.notification-content {
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.notification-content > p {
  padding: 15px;
  text-align: left;
  margin-bottom: 0;
  line-height: 25px;
}

.notification-content > div > button {
  padding: 5px 20px;
}

.fa-spinner {
  animation: spin 4s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ncr-highlighted-step {
  box-shadow: 0 0 4px 1px #ffc107;
}

.ncr-highlighted-arrow {
  color: #ffc107 !important;
}

.ncr-highlighted-notification {
  box-shadow: 0 0 9px 3px #ffc107;
}

.work-instruction-not-submitted-value {
  font-size: 9px;
  color: #a5a3a3;
}

.text-small {
  font-size: 12px !important;
}

.tooltip {
  z-index: 999999;
}

.warning-color {
  color: #ffc107 !important;
}

.warning-color-small {
  color: #856405 !important;
  font-size: 20px !important;
}

.work-instruction-tooltip > .tooltip-inner {
  width: 100% !important;
  max-width: 350px !important;
}

.word-break {
  word-break: break-word;
}

.w-full {
  width: 100%;
}

/* Show React Modal Windows always on top of the rest of the UI elements */
.ReactModal__Overlay {
  z-index: 2000 !important;
}

.barcode-reader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  position: relative;
  width: 100%;
  height: 568px;
}

.barcode-reader-content {
  position: absolute;
  top: 0;
}

.barcode-viewer {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 88.75%;
  background-image: url("/public/static/barcode.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.barcode-medium {
  width: 70% !important;
}

.barcode-container-medium {
  height: 400px !important;
}

.barcode-viewer::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background: #fff;
  height: 10px;
  animation: slideUpDown 2.5s ease-in-out infinite alternate;
}

@keyframes slideUpDown {
  0%,
  100% {
    top: 25%;
  }

  50% {
    top: 75%;
    transform: translate(-50%, -50%);
  }
}

.barcode-inactive {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 88.75%;
  background-image: url("/public/static/barcode-error.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.barcode-inactive::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  height: 10px;
  background: #ba2423;
  transform: translate(-50%, -50%) rotate(45deg);
}

.barcode-inactive::after {
  content: '';
  position: absolute;
  left: 49%;
  top: 51%;
  width: 80%;
  height: 10px;
  background: #343a40;
  transform: translate(-50%, -50%) rotate(45deg);
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.p-a-0 {
  padding: 0;
}

.m-a-0 {
  margin: 0;
}

.pl0 {
  padding-left: 0;
}

.pr0 {
  padding-right: 0;
}

.barcode-input {
  width: 100%;
  height: 100%;
}

.barcode-alert-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scan-mode-toggle-container {
  position: fixed;
  left: 20px;
  padding: 5px 10px;
  bottom: 20px;
  border: 1px solid #000;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.scan-mode-toggle-container-not-fixed {
  position: initial !important;
  width: max-content !important;
  transform: translate(100%) !important;
}

@media screen and (max-width: 768px) {
  .scan-mode-toggle-container {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    max-width: max-content;
    width: max-content;
  }

  .scan-mode-toggle-container-not-fixed {
    position: initial !important;
    transform: translate(50%) !important;
  }
}

.scan-mode-toggle-switch {
  width: 62px;
  height: 32px;
  border: 2px solid black;
  margin: 0 14px;
  border-radius: 50px;
  background-color: white;
  position: relative;
}

.scan-mode-toggle-knob {
  cursor: pointer;
  width: 25px;
  height: 24px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 3px;
  transition: left 0.2s;
}

.scan-mode-toggle-switch.barcode-mode .scan-mode-toggle-knob {
  left: 30px;
}

.small-status-dot {
  display: inline-block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.regular-status-dot {
  display: inline-block;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  vertical-align: text-bottom;
}

.medium-status-dot {
  margin: 2px;
  display: inline-block;
  border-radius: 50%;
  width: 34px;
  height: 34px;
}

.material-batch-card-details {
  margin-top: 44px;
  border-radius: 7px;
  background: rgb(241, 245, 249);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  margin-bottom: 60px;
}

.material-batch-card-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(234, 237, 240);
  border-radius: 7px 7px 0 0;
  color: rgba(117, 117, 120, 0.9);
  font-weight: 400;
  font-size: 1rem;
  padding: 13px 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.permanent-container-card-details-header > p {
  margin-bottom: 0 !important;
  font-size: 18px;
}

.material-lot-card-details-header > p {
  margin-bottom: 0 !important;
  font-size: 18px;
}

.material-batch-card-details-header > p {
  margin-bottom: 0 !important;
  font-size: 18px;
}

.material-batch-card-details-location {
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}

.batch-card-expanded-header-secondary > p {
  color: #fff;
}

.notification-link {
  color: #0b5460;
}

.permanent-container-card-details-header > div > p {
  margin-bottom: 0 !important;
  font-size: 18px;
}

.material-batch-card-details-header > div > p {
  margin-bottom: 0 !important;
  font-size: 18px;
}

.material-batch-card-details-icon-size {
  width: 18px !important;
  height: 18px !important;
}

.material-batch-card-details-header-batch {
  cursor: pointer;
  color: #4fa6d8;
  font-weight: bold;
}

.material-batch-card-details-body {
  padding: 16px;
  color: #a6a6a8;
}

.spacer-left {
  margin-left: 6px;
}

.spacer-right {
  margin-right: 6px;
}

.font-light {
  font-weight: 200;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.align-items-base {
  align-items: baseline;
}

.align-self-center {
  align-self: center;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 600;
}

.unload-hopper-input {
  width: 105px;
  max-width: 100%;
  height: 35px;
  margin-right: 5px;
}

.unload-hopper-input-slider {
  margin: 0 5px;
}

.unload-hopper-input-input-text {
  width: 100px;
  display: flex;
}

.unload-hopper-input-input-text > span:first-child {
  width: 30px !important;
}

.unload-hopper-input-input-text > span:last-child {
  width: 50px !important;
}

.powder_quality_icon {
  margin-left: 5px;
}

.sieve-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.react-toast-notifications__container {
  z-index: 2001 !important;
}

.link-btn {
  display: block;
  text-decoration: none !important;
  margin-top: 0.5rem;
}

.resource-input-field {
  min-width: 220px;
  max-width: 220px;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
}

.tooling-stock-card {
  background-color: #f1f5f9;
  border-radius: 15px;
  padding: 25px 20px 15px 20px;
  margin: 1rem 0;
  position: relative;
}

.tooling-stock-card-sm {
  background-color: #f1f5f9;
  border-radius: 15px;
  padding: 10px 15px;
  margin: 1rem 0;
}

.tooling-stock-card-expand {
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 50%;
  color: #757578;
}

.tooling-stock-card-expand > svg {
  transform: rotate(270deg);
}

.tooling-stock-card-collapse {
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 50%;
  color: #757578;
}

.tooling-stock-card-collapse > svg {
  transform: rotate(270deg);
  font-size: 20px;
}

.tooling-stock-card-main-info {
  margin-left: 5px;
  text-align: left;
}

.tooling-stock-card-main-info-sm {
  margin-left: 5px;
  text-align: left;
  width: 100%;
}

.tooling-stock-card-details {
  display: flex;
  margin-bottom: 20px;
}

.tooling-stock-card-details-sm {
  display: flex;
}

.tooling-stock-card-tooling-icon {
  color: #757578;
  font-size: 33px;
}

.tooling-stock-card-tooling-icon-sm {
  color: #757578;
  font-size: 18px;
}

.tooling-stock-card-location-icon {
  margin-top: 5px;
  color: #a6a6a8;
}

.tooling-stock-card-title {
  font-size: 27px;
  color: #757578;
  font-weight: 500;
  margin-bottom: 0;
}

.tooling-stock-card-title-sm {
  color: #757578;
  font-weight: 500;
  margin-bottom: 0;
}

.tooling-stock-card-title-secondary-sm {
  font-size: 14px;
  font-weight: 300;
  color: #757578;
  margin-bottom: 0;
}

.tooling-stock-card-title-secondary {
  font-weight: 300;
  color: #757578;
  margin-bottom: 0;
}

.tooling-stock-card-location-details {
  text-align: left;
  margin-left: 2px;
}

.tooling-stock-card-secondary-info {
  color: #a6a6a8;
  display: flex;
  justify-content: space-between;
  padding: 0 45px;
}

.tooling-stock-card-location-mobile {
  text-align: left;
  margin-top: 10px;
}

.tooling-stock-card-sub-location-mobile {
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 19px;
}

.truncated-title-text {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 300px) and (max-width: 600px) {
  .tooling-stock-card {
    padding: 15px 15px 10px 15px;
  }

  .tooling-stock-card-main-info {
    margin-left: 3px;
  }

  .tooling-stock-card-tooling-icon {
    font-size: 25px;
  }

  .tooling-stock-card-title {
    font-size: 20px;
  }

  .tooling-stock-card-title-sm {
    font-size: 18px;
  }

  .tooling-stock-card-title-secondary-sm {
    font-size: 14px;
  }

  .tooling-stock-card-details {
    margin-bottom: 15px;
  }

  .tooling-stock-card-title-secondary {
    font-size: 14px;
  }

  .tooling-stock-card-location-details > p:last-child {
    font-size: 12px;
  }

  .tooling-stock-card-secondary-info {
    padding: 0;
    align-items: flex-start;
  }
}

.alert-link {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 14px;
}

.batch-options-button-wrapper {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  justify-content: center;
  gap: 0;
}

.batch-options-button-set {
  display: flex;
}

.batch-option-button {
  padding: 10px;
}

@media (min-width: 300px) and (max-width: 600px) {
  .batch-options-button-wrapper {
    grid-template-columns: repeat(2, min-content);
  }

  .batch-options-button-set {
    display: block;
  }

  #scrapButton {
    order: -1 !important;
  }

  #sieveButton {
    order: 0 !important;
  }

  #treatmentButton {
    order: 1 !important;
  }

  #testButton {
    order: 4 !important;
  }
}

#splitButton {
  order: -3;
}

#blendButton {
  order: -2;
}

#scrapButton {
  order: 0;
}

#sieveButton {
  order: -1;
}

#treatmentButton {
  order: 2;
}

#relocateButton {
  order: 3;
}

#testButton {
  order: 1;
}

#loadButton {
  order: 4;
}

.batch-card-small {
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  background-color: #f1f5f9;
  box-shadow: 0 0 3px 0 #00000047;
}

.batch-card-small-main-details-title {
  font-size: 18px;
  font-weight: 200;
  color: #fff;
  margin-left: 6px;
}

.batch-card-small-main-details-info {
  padding: 12px 15px;
}

.batch-card-small-main-details-powder-quality {
  text-align: left;
  padding: 0 8px;
}

.batch-card-small-main-details {
  border-radius: 15px 0 0 15px;
  background-color: #496eb2;
}

.batch-card-small-right-details {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #496eb2;
  border-radius: 0 15px 15px 0;
  padding: 15px 0;
  height: 100%;
}

.batch-card-small-center-details {
  margin: auto 15px;
  text-align: right;
}

.batch-card-small-secondary-details {
  display: flex;
  align-items: center;
}

.batch-card-main-details-main-link {
  display: flex;
  align-items: center;
  margin: 0;
  color: #f1f5f9;
  transition: 0.3s;
}

.batch-card-main-details-main-link:hover {
  color: #fff;
}

.batch-card-small-secondary-details-units {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.batch-card-expanded {
  display: block;
  box-shadow: 0 0 3px 0 #00000047;
  border-radius: 15px;
}

.batch-card-expanded-header {
  display: flex;
  justify-content: space-between;
  background-color: #496eb2;
  border-radius: 15px 15px 0 0;
  padding: 15px;
  border-bottom: 5px solid #b0b0b0;
}

.batch-card-expanded-header-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.batch-card-expanded-header-secondary {
  text-align: right;
}

.batch-card-expanded-header-main-details {
  display: flex;
}

.batch-card-expanded-header-status-details {
  display: flex;
}

.batch-card-expanded-header-main-batch-title {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 200;
  color: #fff;
}

.batch-card-expanded-header-location-details {
  color: #fff;
  text-align: left;
  margin-left: 2px;
}

.batch-card-expanded-header-location {
  display: flex;
  margin-left: 15px;
}

.batch-card-expanded-header-location::before {
  display: block;
  content: '';
  height: 85%;
  background-color: #fff;
  width: 1px;
  margin-right: 15px;
  align-self: center;
}

.batch-card-expanded-body {
  background-color: #f1f5f9;
  border-radius: 0 0 15px 15px;
  position: relative;
  padding-bottom: 30px; /* Adjust based on the space needed for dots */
}

.batch-card-expanded-body-tabs {
  display: flex;
  padding: 10px 25px;
  position: relative;
}

.batch-card-expanded-body-tabs::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #496eb2;
  z-index: 1;
  margin: 10px 25px;
}

.batch-card-expanded-body-tab {
  display: block;
  border-radius: 5px 5px 0 0;
  font-size: 18px;
  font-weight: 400;
  transition: 0.3s;
}

.batch-card-expanded-body-tab:focus {
  box-shadow: unset !important;
}

.batch-card-expanded-body-tab:hover {
  background-color: #3d63aa;
  color: #fff;
}

.batch-card-expanded-body-tab-active {
  background-color: #496eb2;
  color: #fff;
}

.material-lot-card-details {
  border-radius: 7px;
  background: #f1f5f9;
  margin: 20px 35px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.08);
}

.material-lot-card-details-header {
  display: flex;
  align-items: center;
  padding: 13px 15px;
  border-radius: 7px 7px 0 0;
  background: #eaedf0;
  color: #000;
  font-weight: normal;
}

.material-lot-card-details-body {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  color: #000;
}

.material-lot-card-details-body-right-details {
  text-align: right;
}

.material-lot-card-details-body-quantity-icon {
  font-size: 25px;
}

.batch-card-expanded .slick-dots {
  position: absolute;
  bottom: -10px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.batch-card-expanded .slick-dots li button::before {
  font-size: 15px;
  color: #757474;
}

.batch-card-expanded .slick-dots li.slick-active button::before {
  font-size: 15px;
  color: #5f5f5f;
}

.permanent-container-card-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 15px;
  border-radius: 7px 7px 0 0;
  background: #eaedf0;
  color: #000;
  font-weight: normal;
}

.batch-card-expanded .material-lot-card-details-header {
  border-bottom: 3px solid #7575788a;
}

.batch-card-expanded .permanent-container-card-details-header {
  border-bottom: 3px solid #50a6d8;
}

.batch-card-expanded-toggle {
  background-color: #496eb2;
  color: #fff;
  position: absolute;
  right: 0;
  font-weight: 700;
  border-radius: 15px 0 15px 0;
}

.batch-card-expanded-location-mobile-btn {
  background-color: #f1f5f9;
  color: #5e5e5e;
}

.batch-card-expanded-location-mobile {
  text-align: left;
  margin-top: 10px;
  color: #fff;
}

.batch-card-expanded-sub-location-mobile {
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 19px;
}

.batch-card-expanded-header-secondary-details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.batch-card-header-secondary-icon {
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 16px;
  margin: 0 4px;
}

.error-loading-card-resource {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px dashed #f31414;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  color: #f31414;
  margin: 20px 40px;
}

.no-card-resources {
  display: flex !important;
  align-items: center;
  padding: 10px;
  border: 1px dashed #496eb2;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  color: #496eb2;
  margin: 20px 40px;
  width: inherit !important;
}

@media (min-width: 300px) and (max-width: 600px) {
  .error-loading-card-resource {
    font-size: 14px;
    margin: 20px 25px;
  }
}

.permanent-container-card-details-header-title {
  cursor: pointer;
  color: #4fa6d8;
  font-weight: bold;
}

.permanent-container-card-details {
  margin-top: 44px;
  border-radius: 7px;
  background: #f1f5f9;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 60px;
}

.permanent-container-card-details-body {
  padding: 16px;
  color: #000;
}

.permanent-container-card-details-location {
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}

.batch-info-color {
  color: #496eb2;
}

.powder_quality_badge {
  cursor: pointer;
  padding: 5px 6px;
  margin-left: 5px;
  border-radius: 17px;
  border: 1px solid #d0d2d9;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.powder_quality_badge_smaller {
  padding: 1px 6px !important;
}

.powder_quality_badge_darken {
  background-color: #e8eaef;
  color: #000;
  border: 1px solid #a9b0c9;
}

.action-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  z-index: 1000;
  height: auto;
  min-height: 30vh;
  max-height: 80vh;
  border-radius: 15px 15px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.action-panel-sidebar {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  z-index: 1000;
  height: 100vh;
  min-height: 30vh;
  overflow-y: auto;
}

@media (min-width: 300px) and (max-width: 600px) {
  .action-panel {
    left: 0 !important;
    right: unset;
    width: 100%;
  }

  .action-panel-sidebar {
    bottom: 0;
    left: 0;
    margin: 0 auto;
    right: unset;
    width: 100%;
    border-radius: 15px 15px 0 0;
    height: auto;
    max-height: 90%;
  }
}

.action-panel-backdrop {
  background-color: rgb(0 0 0 / 70%);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.action-panel-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 15px;
  border-radius: 15px 15px 0 0;
  color: #343434;
  font-weight: 600;
  font-size: 18px;
}

.action-panel-body {
  background-color: #fff;
  padding: 15px 20px;
}

.action-panel-cancel-btn {
  color: #00000069;
  font-size: 16px;
  font-weight: 400;
}

.action-panel-close-btn-icon {
  font-size: 32px;
  color: #6d6d6d;
}

.action-panel-confirm-btn {
  color: #007bff;
  font-size: 16px;
  font-weight: 600;
}

.action-panel-notes-field {
  min-height: 100px;
  width: 100%;
}

.action-panel-success-message {
  animation: delayed-appearance 0.5s linear 0s;
  text-align: center;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 15px;
}

.action-panel-error-message {
  animation: delayed-appearance 0.5s linear 0s;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
  color: #a00000;
}

.action-panel-close-btn {
  position: absolute;
  right: 10px;
}

.loading-container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.checkmarkContainer {
  position: relative;
  margin: 0 auto 20px;
  display: block;
  width: 152.5px;
  height: 138.5px;
}

.checkmarkWrapper {
  position: absolute;
  background: white;
  width: 100%;
  height: 100%;
}

.svgContainer {
  position: absolute;
  width: 152.5px;
  height: 138.5px;
  top: 0;
  left: 0;
}

.tickGreen,
.tickYellow,
.tickGray {
  position: absolute;
  fill: none;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 152;
  stroke-dashoffset: 0;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both;
}

.tickGreen {
  stroke: #4ab71a;
  animation-name: tickGreenAnimation;
  animation-duration: 290ms;
  animation-delay: 868ms;
}

.tickYellow {
  stroke: #fb0;
  animation-name: tickYellowAnimation;
  animation-duration: 292ms;
  animation-delay: 637ms;
}

.tickGray {
  stroke: #ececec;
  animation-name: tickGrayAnimation;
  animation-duration: 276ms;
  animation-delay: 537ms;
}

.circleGray,
.circleLightBlue,
.circleGreen {
  position: absolute;
  fill: none;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  transform-origin: 69px 69px;
  stroke-dasharray: 337.5;
  animation-duration: 517ms, 529ms;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both, both;
}

.circleGreen {
  stroke: #4ab71a;
  animation-name: rotateCircleDarkBlue, dashCircleDarkBlue;
  animation-delay: 270ms, 278ms;
}

.circleLightBlue {
  stroke: #fb0;
  animation-name: rotateCircleLightBlue, dashCircleLightBlue;
  animation-delay: 159ms, 157ms;
}

.circleGray {
  stroke: #ececec;
  animation-name: rotateCircleGray, dashCircleGray;
  animation-delay: 58ms, 58ms;
}

.action-spinner {
  position: relative;
  width: 117px;
  height: 117px;
}

.action-spinner::before,
.action-spinner::after {
  content: "";
  display: block;
  position: absolute;
  width: 117px;
  height: 117px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  border-color: #2069a0;
  top: 0;
  left: 0;
  animation: scale-action-spinner 1s linear 0s infinite;
}

.action-spinner::after {
  animation-delay: 0.5s;
  opacity: 0;
}

.error-checkmark-wrapper {
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-checkmark {
  width: 95.2px;
  height: 95.2px;
  border-radius: 50%;
  display: block;
  stroke-width: 3.4;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 #f31414;
  animation: error-fill 0.4s ease-in-out 0.4s forwards, error-scale 0.3s ease-in-out 0.9s both;
}

.error-checkmark_circle {
  stroke-dasharray: 282.2;
  stroke-dashoffset: 282.2;
  stroke-width: 3.4;
  stroke-miterlimit: 10;
  stroke: #f31414;
  fill: none;
  animation: error-stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.error-checkmark_check {
  transform-origin: 50% 50%;
  stroke-dasharray: 81.6;
  stroke-dashoffset: 81.6;
  animation: error-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.scanned-run-card {
  margin: 20px 0;
  box-shadow: 0 3px 5px 1px #0000001a;
  border-radius: 0 0 15px 15px;
}

.scanned-run-header {
  background-color: #496eb2;
  border-radius: 15px 15px 0 0;
}

.scanned-run-title-content {
  display: flex;
  color: #fff;
  padding: 11px 15px;
}

.scanned-run-title-icon-content {
  line-height: 2.5;
}

.scanned-run-title-icon {
  color: #fff;
  line-height: 2.5;
  font-size: 22px;
  margin-right: 6px;
}

.scanned-run-title {
  font-size: 22px;
  font-weight: 600;
}

.scanned-run-uuid {
  text-align: left;
  font-size: 14px;
}

.scanned-run-body {
  padding: 15px;
  color: #a6a6a8;
}

.scanned-run-body-item {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.relocate-warning-wrapper {
  position: relative;
  display: inline-block;
}

.relocate-batch-badge-info {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
}

.floating-button-container {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 999;
}

.floating-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #3b5998;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

.floating-button:hover {
  background-color: #2a4372;
  transform: scale(1.1);
}

.floating-button.close {
  opacity: 1;
  background-color: #282c33;
}

.floating-button.close:hover {
  opacity: 1;
  background-color: #1e2023;
}

.floating-button svg {
  font-size: 20px;
  color: #fff;
  animation: none;
}

.barcode-scan-btn > svg {
  color: #027bff;
  font-size: 30px;
  transition: 0.3s;
}

.barcode-scan-btn > svg:hover {
  color: #0c67c9;
}

.floating-button.close svg {
  animation: rotate-close 0.3s forwards;
}

.floating-button svg:not(.close) {
  animation: rotate-open 0.3s forwards;
}

.floating-button-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 60px;
  left: 0;
}

.floating-button-skip-animation > svg:not(.close) {
  animation: none !important;
}

.floating-button-stack .floating-button {
  opacity: 0;
  transform: scale(0);
  animation: none;
}

/* Stack Buttons Enter Animation */
.floating-button-stack .floating-button:nth-child(1) {
  animation: bounce-nds 0.3s ease-in-out forwards;
  animation-delay: 0.1s;
}

.floating-button-stack .floating-button:nth-child(2) {
  animation: bounce-nds 0.3s ease-in-out forwards;
  animation-delay: 0.2s;
}

/* Stack Buttons Exit Animation */
.floating-button-stack .floating-button.exit:nth-child(1) {
  animation: bounce-out-nds 0.3s ease-in-out forwards;
  animation-delay: 0.1s;
}

.floating-button-stack .floating-button.exit:nth-child(2) {
  animation: bounce-out-nds 0.3s ease-in-out forwards;
  animation-delay: 0.2s;
}

.container-qr-code > a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
  transition: 0.3s;
}

.all-containers-active > a {
  text-decoration: none;
  color: #496eb2;
  cursor: pointer;
  transition: 0.3s;
}

.all-containers-active:hover > a {
  color: #2b4575;
  text-decoration: none;
}

.all-containers-active-icon:hover {
  color: #2b4575 !important;
}

.all-containers-active:hover > .all-containers-active-icon {
  color: #2b4575 !important;
}

.container-qr-codes {
  display: flex;
  align-items: baseline;
  padding: 0 10px;
  text-align: center;
}

.container-qr-code-icon {
  font-size: 70px;
  margin-bottom: 10px;
  cursor: pointer;
}

.container-qr-code-icon:hover {
  color: #496eb2;
}

.container-qr-code > a:hover {
  color: #496eb2;
}

.container-qr-code > a:hover > .container-qr-code {
  color: #496eb2;
}

.only-printable {
  display: none;
}

.unit-group {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.units-container {
  display: flex;
  gap: 2rem;
}

.units-select {
  padding: 5px;
  border-radius: 5px;
}

.top-off-current-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(31, 96, 216);
  width: 120px;
  height: 30px;
  color: #fff;
}

.main-content-container {
  padding-bottom: 50px;
}

.container-card-title {
  font-size: 14px;
  font-style: italic;
}

.container-title-uuid {
  font-size: 25px;
  font-style: italic;
  color: #a69898;
}

.resource-column-flexible-height {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-basis: 50%;
}

input::placeholder {
  font-size: 0.98rem;
}
