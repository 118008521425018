.right {
  float: right;
}

.left {
  float: left;
}

.material-chips-container {
  display: flex;
  justify-content: start;
}

.material-chips-content {
  text-align: left;
}

.collapsable-title {
  margin: 4px 0;
  cursor: pointer;
  padding: 8px;
  border: 1px solid lightgrey;
  background-color: rgba(0, 0, 0, 0.03);
  min-height: 40px;
  transition: 0.2s;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapsable-title > .title-container {
  max-width: calc(100% - 40px);
  text-align: left;
}

.collapsable-title.expanded {
  border-radius: 4px 4px 0 0;
}

.collapsable-body {
  margin-top: -5px;
  border-radius: 0 0 4px 4px;
  border: 1px solid lightgrey;
  padding: 4px;
}

.collapsable-body.collapsed {
  display: none;
}

table.align-left td,
table.align-left th {
  text-align: left;
}

.status-cell {
  text-transform: capitalize;
}

img.thumbnail {
  max-width: 100%;
  border-radius: 4px;
}
