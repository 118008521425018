body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  min-width: 0 !important;
}

.traveler {
  border-top: 1px dashed #000;
  text-align: center;
  font-size: 5.5vmin;
  padding: 1em;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  grid-template-areas:
    "model model"
    "info info"
    "qr qr"
    "footer footer";
}

.traveler-secondary {
  display: flex;
  border-top: 1px dashed #000;
  text-align: center;
  font-size: 5.5vmin;
  padding: 1em;
}

.qr-code-printable {
  display: none !important;
}

.qr-code-initial {
  flex-basis: 20%;
}

.qr-code-initial svg {
  max-width: 350px;
}

.print-general-container {
  font-size: 4.5vmin;
  flex-basis: 80%;
}

.print-info-general > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
}

.print-general-footer-mobile {
  display: none;
}

.print-title {
  font-weight: 400;
  color: #444;
  text-align: left;
}

.print-description {
  font-weight: 500;
  color: #000;
}

.print-general-footer {
  margin-top: 30px;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .traveler-secondary {
    display: flex;
    flex-direction: column;
  }

  .qr-code-initial {
    order: 2;
  }

  .print-info-general {
    order: 1;
  }

  .print-general-footer-mobile {
    display: block;
    order: 3;
  }

  .print-general-footer {
    display: none;
    order: 3;
  }
}

@media (orientation: landscape) {
  .traveler {
    grid-template-areas:
      "model info"
      "model info"
      "qr info"
      "qr footer";
  }

  .traveler-secondary {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  .qr-code-initial {
    order: 1;
  }

  .print-general-container {
    order: 2;
  }

  .print-general-footer-mobile {
    display: none;
  }

  .print-general-footer {
    display: block;
  }

  .qr {
    height: 100%;
    width: auto;
  }
}

.traveler:first-child {
  border-top: 0;
}

h1 {
  font-size: 5vmin;
}

.model {
  width: 80%;
  margin: 0 auto 1em;
  grid-area: model;
}

.model img {
  width: 100%;
  max-width: 400px;
  min-width: 250px;
}

.print-info {
  grid-area: info;
  max-width: none !important;
}

.print-info dl {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 0.5em;
  align-items: center;
  text-align: left;
  margin-bottom: 0;
}

.print-info dt {
  text-align: right;
  color: #444;
  font-size: 0.6em;
  font-weight: 400;
}

.print-info dd {
  margin-bottom: 0;
  font-size: 0.6em;
  font-weight: 500;
}

.qr {
  min-width: 40%;
  margin: 1em auto;
  grid-area: qr;
}

.barcode {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr svg {
  width: 100%;
  height: 100%;
}

/* .barcode > a > svg { */

/*  width: 500px !important; */

/* } */

footer {
  grid-area: footer;
}

footer p {
  font-size: 4vmin;
  margin-bottom: 0;
}

.logo {
  vertical-align: middle;
  width: 1.2em;
  height: 1.2em;
}

@media print {
  @page {
    width: 100%;
    height: 100%;
    margin: 2%;
    size: auto;
  }

  .traveler {
    page-break-inside: avoid;
    page-break-after: auto;
    border-top: 0;
    padding: 0;
  }

  .traveler-secondary {
    padding: 5%;
    page-break-inside: avoid;
    page-break-after: auto;
    border-top: 0;
  }

  .only-printable {
    display: initial !important;
  }

  .non-printable {
    display: none;
  }

  .model {
    margin: 0 auto 0.5em;
  }

  .print-info h1 {
    margin-bottom: 0;
  }

  .print-block > div {
    line-height: 1.5;
    font-size: 5.5vmin;
    font-weight: 600;
  }

  .print-block {
    text-align: left;
  }

  .print-title {
    font-weight: 600;
    text-align: left;
    color: #000;
  }

  .print-description {
    font-weight: 600;
  }

  .qr-code-printable {
    display: block !important;
    max-width: 150px;
    margin: 25px;
  }

  .qr-code-initial {
    display: none !important;
  }

  .qr {
    margin: 0.5em auto 0.25em;
  }

  .print-general-footer-mobile {
    display: none;
  }

  .print-general-footer {
    display: block;
  }

  .barcode {
    min-width: 40%;
    grid-area: qr;
    margin: 0.5em auto 0.25em;
  }

  .barcode svg {
    width: 100%;
    height: 100%;
  }

  .hide-on-print {
    display: none;
  }
}
