.comments-icon {
  position: fixed;
  top: 49vh;
  right: 20px;
  cursor: pointer;
}

.comments-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1002;
}

.comments-container__header {
  height: 50px;
  border-bottom: 1px solid #636b73;
  margin-bottom: 17px;
}

.comments-container__header__title {
  float: left;
  width: 50%;
}

.comments-close-top {
  background: transparent;
}

.comments-content {
  background-color: white;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.25);
  padding: 18px;
}

.comments-content .comments-header {
  font-size: 1.5em;
  margin-bottom: 18px;
  border-bottom: 1px solid gray;
}

.comments-container__body {
  padding-bottom: 116px;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .comments-close-top {
    height: 30vh;
  }

  .comments-content {
    height: 70vh;
  }

  .comments-container__body {
    height: calc(70vh - 120px);
  }
}

@media screen and (min-width: 769px) {
  .comments-close-top {
    height: 50vh;
  }

  .comments-content {
    height: 50vh;
  }

  .comments-container__body {
    height: calc(50vh - 120px);
  }
}

.comment {
  margin-top: 12px;
  min-height: 105px;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 16px;
}

.comment__avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  float: left;
  background-color: forestgreen;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment__body {
  min-height: 84px;
  width: calc(100% - 72px);
  font-size: 14px;
  overflow-y: auto;
}

.comment__body__content {
  overflow-y: auto;
  width: 100%;
}

.comment__body__content pre {
  word-wrap: wrap;
}

pre code {
  word-break: break-word;
}

.comment__header__info {
  margin-left: 10px;
}

.comment__header__info__date {
  float: right;
  font-size: 14px;
  top: -32px;
  position: relative;
}

.comment__header__info__name {
  margin-top: 5px;
  max-width: calc(100% - 100px);
}

.order__info {
  text-align: right;
  font-size: 14px;
  position: relative;
  top: -22px;
  font-style: italic;
  height: 15px;
}

.order__info::first-letter {
  text-transform: capitalize;
}

.comments-container__header .dropdown-toggle {
  color: #000;
  background-color: #f4f4f4;
  border: none;
}

.comments-container__header .dropdown button {
  border-radius: 6px;
}

.new-comment__wrapper {
  position: fixed;
  bottom: 0;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 15px 16px 15px 21px;
  background-color: #f4f4f4;
}

.mention-users__icon {
  font-size: 26px;
  color: #409cff;
  cursor: pointer;
  border-radius: 12px;
  background-color: white;
  width: 60px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-comment__input {
  position: relative;
  width: calc(100% - 80px);
  border-radius: 12px;
  border: none;
}

.react-mde {
  border: none;
  width: 100%;
}

.react-mde.react-mde-tabbed-layout {
  border: none;
}

.react-mde .mde-header {
  border: none;
  background: transparent;
  justify-content: space-between;
  position: relative;
  top: -10px;
  height: 48px;
  overflow-y: auto;
  overflow-x: auto;
}

.mde-textarea-wrapper {
  /* width: calc(100% - 80px); */
  border-radius: 12px;
  float: left;
  margin-right: 11px;
  width: 100%;
}

.mde-textarea-wrapper textarea,
.react-mde .mde-textarea-wrapper textarea.mde-text {
  height: 44px !important;
  border-radius: 12px;
  resize: none;
  padding-left: 32px;
  left: -18px;
}

.mde-preview {
  width: calc(100% - 80px);
  float: left;
  margin-right: 11px;
}

.react-mde .mde-preview {
  min-height: auto !important;
  max-height: 60px;
  overflow-y: auto;
}

.react-mde .mde-header .mde-tabs button {
  color: white;
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 29px;
  padding: 0 8px;
  outline: none;
  margin-top: 12px;
  font-size: 14px;
}

.mde-header .mde-tabs button.selected {
  background-color: #28a745;
  outline: none;
  border-radius: 6px;
}

.new-form__submit {
  position: relative;
  width: 60px;
  border-radius: 12px;
  height: 44px;
}

.user-picker__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  padding: 56px 20px;
  z-index: 2;
}

.user-picker__header {
  height: 45px;
}

.picker__headings {
  font-size: 30px;
  line-height: 45px;
}

.user-picker__goback {
  width: 40px;
  float: left;
  cursor: pointer;
}

.user-picker__goback > span {
  font-size: 30px;
}

.name-container > span,
.name-container > p {
  line-height: 33px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100vw - 111px);
}

.single-user__row {
  height: 72px;
  padding: 9px;
  border-radius: 10px;
  background-color: #f4f4f4;
  margin-bottom: 8px;
}

.single-user__row > .comment__avatar {
  height: 33px;
  width: 33px;
  font-size: 16px;
}

.single-user__row > .comment__avatar > span {
  font-size: 16px;
}

.name-container {
  width: calc(100% - 72px);
  float: left;
}

.selector__container > input {
  height: 20px;
  width: 20px;
  margin-top: 8px;
}

.user-picker__list {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
